@tailwind base;
@tailwind components;

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

body {
  @apply font-body text-base;
}

.asap-font {
  font-family: 'Asap', sans-serif;
}

.responsive-table-header-cell {
  font-size: 15px;
  @apply text-sm py-1 text-gray-midGray text-right font-bold bg-gray-alabaster border-gray-tundora hidden;
}

.responsive-table-header-cell:first-child > svg {
  margin: auto;
}

.responsive-table-data-cell {
  @apply text-blue-pickedBluewood text-right w-full text-gray-mako border-gray-mercury border-b block relative;
}

.responsive-table-data-hidden-header {
  font-size: 14px;
  @apply block top-0 left-0 bg-gray-alabaster px-2 py-0.5 font-bold text-black;
}

.header-button {
  @apply px-0.5 border-b-2 border-transparent mx-4 font-medium text-gray-midGray;
}

.header-button:hover {
  @apply border-green-haze text-green-haze;
}

.header-button:focus {
  @apply outline-none;
}

.slider-thumb::-webkit-slider-thumb {
  @apply appearance-none w-4 h-4 rounded-full bg-green-bush cursor-pointer;
}

.slider-thumb::-webkit-slider-thumb:hover {
  @apply bg-blue-pickedBluewood;
}

.disabled-input:disabled {
  @apply bg-white;
}

.responsive-table-data-row {
  @apply bg-white flex table-row mb-10 md:mb-0;
}

.responsive-table-data-row:not(.expanded-row):hover .responsive-table-data-cell {
  @apply bg-yellow-quarterPearlLusta;
}

.responsive-table-data-row.expanded-row > td {
  @apply bg-yellow-bajaWhite border-b-0;
}

.responsive-table-data-row.expanded-row .expanded-table-row-cell > td {
  @apply bg-yellow-quarterPearlLusta;
}

.toggle-switch {
  @apply bg-transparent box-border flex flex-grow-0 flex-shrink-0 h-6 mt-0 mr-0 overflow-hidden p-0 relative w-10;
}

.toggle-switch-label {
  @apply cursor-pointer m-0 p-0 w-full relative;
}

.toggle-switch-input {
  @apply opacity-0;
}

.toggle-switch-handle {
  @apply left-0.5 bg-white rounded-2xl block h-3 p-0 absolute top-0 bottom-0 m-auto transition-transform w-3;
}

.toggle-switch-text {
  @apply h-4 m-0 absolute top-0 bottom-0 m-auto w-9 bg-gray-mercury rounded-3xl block;
}

.toggle-switch-text:after,
.toggle-switch-text:before {
  @apply transition-transform absolute leading-6 font-medium text-xs text-gray-midGray;
}

.toggle-switch-text:after {
  transform: translateX(-200%);
  @apply opacity-0 left-2 text-white;
}

.toggle-switch-text:before {
  transform: translateX(0);
  @apply right-2;
}

.toggle-switch-input:checked ~ .toggle-switch-text {
  @apply bg-gray-midGray;
}

.toggle-switch-input:checked ~ .toggle-switch-handle {
  transform: translateX(20px);
}

.toggle-switch-input:checked ~ .toggle-switch-text:before {
  transform: translateX(200%);
  @apply opacity-0;
}

.toggle-switch-input:checked ~ .toggle-switch-text:after {
  opacity: 1;
  transform: translateX(0);
}

.search-results-table-wrapper {
  height: 40rem;
  overflow-y: scroll;
}

.loader {
  border-color: #e3e3e3;
  border-top-color: #00a24b;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.input-container:focus-within {
  @apply border bg-green-panache;
}

.number-input-landing-page:focus-visible,
.number-input-landing-page:focus-visible ~ div {
  outline: none;
  @apply bg-green-panache;
}

.number-input-search-page:focus-visible,
.number-input-container:focus-within .icon,
.number-input-container:focus-within span {
  outline: none;
  @apply bg-yellow-brightSun font-bold;
}

.number-input-container:focus-within {
  @apply bg-yellow-brightSun;
}

.occupancy-select:focus-visible,
.select-occupancy-container:focus-within {
  outline: none;
  @apply bg-yellow-brightSun;
}

.number-input:focus-visible ~ .icon > svg {
  @apply text-green-summerGreen;
}

.zipcode-tag {
  @apply asap-font rounded flex items-center bg-green-panache px-3 py-0.5 mr-2 cursor-pointer text-xl text-blue-pickedBluewood;
}

.negative-z-index {
  z-index: -1;
}

@screen md {
  .responsive-table-header-cell {
    font-family: 'Asap', sans-serif;
    @apply table-cell;
  }

  .responsive-table-header-cell:nth-child(2) {
    @apply text-left;
  }

  .responsive-table-header-cell:last-child {
    @apply pr-5;
  }

  .responsive-table-data-cell {
    font-family: 'Asap', sans-serif;
    @apply w-auto table-cell static py-3;
  }

  .responsive-table-data-cell:nth-child(2) {
    @apply text-left;
  }

  .responsive-table-data-row:last-child .responsive-table-data-cell {
    @apply border-b-0;
  }

  .responsive-table-data-hidden-header {
    @apply hidden;
  }

  .expanded-table-row-cell {
    @apply bg-yellow-quarterPearlLusta py-14 border-b border-gray-mercury;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind utilities;

th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
}
